import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../components/Layout';

import imgBlockR from '../assets/images/course-givaway.jpeg';
import imgDots from '../assets/images/home-page/Dots.png';

import '../assets/styles/pages/brexit-guide-page.scss';

const EMPTY_FORM_DATA = {
  firstName: '',
  lastName: '',
  userEmail: '',
};

const CourseGivaway = () => {
  const [formData, setFormData] = useState(EMPTY_FORM_DATA);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const sourceLabel = 'Free Course Application';
    const MailchimpData = {
      FNAME: formData.firstName,
      LNAME: formData.lastName,
      MMERGE4: new Date().toISOString().split('T')[0], // contact date
      MMERGE17: sourceLabel, // Source
    };

    await fetch('/api/download-brochure', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        sourceLabel,
        userFromGoogleAds: window?.userFromGoogleAds,
        userFromFacebookAds: window?.userFromFacebookAds,
        userFromGoOverseas: window?.userFromGoOverseas,
        userFromGoAbroad: window?.userFromGoAbroad,
      }),
    });
    addToMailchimp(formData.userEmail, MailchimpData);

    setFormData(EMPTY_FORM_DATA);

    navigate('/birthday-competition-terms-conditions/thank-you');
  };

  return (
    <Layout title="Free Course" description="Get Your Free Course">
      <div className="brexit-guide-page">
        <section className="first-section">
          <div className="container">
            <div className="row top top--giveway">
              <div className="col-12 col-lg-6">
                <div className="block_l">
                  <h1 className="c-title-42">Win a year at TEFL Iberia!</h1>
                  <p className="c-text-18">
                    Have you been dreaming of a year abroad teaching English in Spain? Turn your
                    dreams into reality by entering our 10th birthday celebration giveaway and win
                    our Trinity CertTESOL course and a year on our Developing Teacher Course, valued
                    at €2850!{' '}
                  </p>
                  <p className="c-text-18">
                    Winners will be announced on <b>July 15th 2022.</b>
                  </p>

                  <div className="block_r__img">
                    <img
                      src={imgBlockR}
                      alt="Course giveaway competition"
                      className="block_r__img"
                    />
                  </div>

                  <div className="block__logo block_r__trinity">
                    <img src={imgDots} alt="iconTrinity" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 order-2">
                <div className="block_l">
                  <h2 className="c-title-42">Enter the prize draw</h2>
                  <p className="c-text-18">Join our course giveaway competition!</p>
                  <form id="free-course" onSubmit={handleSubmit} className="form">
                    <h1 className="c-title-22 form__title">Get your free Course now</h1>

                    <label className="form__first-name">
                      <p className="c-text-14 text-under">First Name</p>
                      <input
                        className="input__first-name"
                        type="text"
                        placeholder="John"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label className="form__last-name">
                      <p className="c-text-14 text-under">Last Name</p>
                      <input
                        className="input__last-name"
                        type="text"
                        placeholder="Smith"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>

                    <label className="form__first-name email">
                      <p className="c-text-14 text-under">Email</p>
                      <input
                        className="input__email"
                        type="email"
                        placeholder="johnsmith@gmail.com"
                        name="userEmail"
                        value={formData.userEmail}
                        onChange={handleInputChange}
                        required
                      />
                    </label>

                    <div className="form__checkbox term">
                      <input
                        id="agree-checkbox-1"
                        type="checkbox"
                        value={true}
                        name="agreeRules"
                        required
                      />

                      <label htmlFor="agree-checkbox-1">
                        <span className="label">
                          By entering the competition you accept our{' '}
                          <Link to="/birthday-competition-terms-conditions" className="red-link">
                            terms and conditions
                          </Link>
                        </span>
                      </label>
                    </div>
                    <button type="submit" className="form__btn c-btn c-btn--red">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="second-section">
          <div className="container container--givaway ">
            <div className="row">

              <div className="col-12 ">
                <div className="block_r">
                 
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </Layout>
  );
};

export default CourseGivaway;
